<template>
	<div>
		<en-table-layout
			toolbar
			pagination
			:tableData="pageData.data"
			:height="700"
			@selection-change="handleSelectionChange"
			:loading="loading">
			<div slot="toolbar" class="inner-toolbar">
				<div class="toolbar-btns">
					<!--商品状态 上架 下架-->
					<el-button @click="handleGoodsAudit(idsList, true)"  type="primary">批量改价</el-button>
					<el-button @click="phandleOperateGoods()"  type="primary">批量上架</el-button>
					<el-button @click="handleGoodsAudit(idsList, true)"  type="primary">批量下架</el-button>
					<br>
					<br>
					<div class="conditions">
						<span>上下架：</span>
						<el-select style="width: 180px;" v-model="params.market_enable" placeholder="请选择上下架">
						    <el-option
						    	label="上架"
						    	value="1">
						    </el-option>
						    <el-option
						    	label="下架"
						    	value="0">
						    </el-option>
						</el-select>
					</div>
					<div class="conditions">
						<span>审核状态：</span>
						<el-select style="width: 180px;" v-model="params.is_auth" placeholder="请选择商品分类">
						    <el-option
								label="待审核"
								value="0">
						    </el-option>
							<el-option
								label="审核通过"
								value="1">
							</el-option>
							<el-option
								label="审核拒绝"
								value="2">
							</el-option>
						</el-select>
					</div>
					<!--商品类型-->
					<div class="conditions">
						<span>商品分类：</span>
						<el-select style="width: 180px;" v-model="params.goods_ptype" placeholder="请选择商品分类">
						    <el-option
								label="实物商品"
								value="1">
						    </el-option>
							<el-option
								label="虚拟商品"
								value="2">
							</el-option>
						</el-select>
					</div>
					<br>
					<br>
					<!--商品分组 获取分组列表-->
					<!-- <div class="conditions">
						<span>店铺分组：</span>
						<en-category-picker class="choose-machine" size="mini" @changed="changeGoodsCateGory" :clearable='true'/>
					</div> -->
					<div class="conditions">
						<span>会员价：</span>
						<el-input style="width: 70px;" :controls="false" v-model="params.low_price"></el-input>
						<span style="margin: 0 5px;">-</span>
						<el-input style="width: 70px;" :controls="false" v-model="params.high_price"></el-input>
					</div>
					<div class="conditions" style="margin-right: 20px;">
						<span>输入搜索：</span>
						<el-input style="width: 180px;" v-model="params.keyword" placeholder="输入关键词"></el-input>
					</div>
					<el-button @click="GET_GoodsList" type="primary">搜索</el-button>
					<!-- <div class="conditions">
						<span>利润率%：</span>
						<el-input-number style="width: 70px;" :controls="false" v-model="params.dddd" :min="0"></el-input-number>
						<span style="margin: 0 5px;">-</span>
						<el-input-number style="width: 70px;" :controls="false" v-model="params.eeee" :min="0"></el-input-number>
					</div> -->
					<!-- <el-button @click="publishGoods" type="primary" >发布商品</el-button>
					<el-button @click="gotoRecycle" type="primary">回收站</el-button>
					<el-button @click="upBatch" type="success">批量上架</el-button>
					<el-button @click="underBatch" type="danger">批量下架</el-button> -->
				</div>
			</div>
			<template slot="table-columns">
				<el-table-column
					type="selection"
					width="55">
				</el-table-column>
				<el-table-column label="图片" width="120">
					<template slot-scope="scope">
						<img :src="scope.row.thumbnail" class="goods-image"/>
					</template>
				</el-table-column>
				<el-table-column label="商品名称" :show-overflow-tooltip="true" width="220">
					<template slot-scope="scope">
						{{ scope.row.goods_name }}
					</template>
				</el-table-column>
				<el-table-column label="商品分类">
					<template slot-scope="scope">
						{{ scope.row.category_path }}
					</template>
				</el-table-column>
				<el-table-column label="成本价">
					<template slot-scope="scope">
						{{ scope.row.cost | unitPrice('￥') }}
					</template>
				</el-table-column>
				<el-table-column label="市场价">
					<template slot-scope="scope">
						{{ scope.row.mktprice | unitPrice('￥') }}
					</template>
				</el-table-column>
				<el-table-column label="会员价">
					<template slot-scope="scope">
						{{ scope.row.price | unitPrice('￥') }}
					</template>
				</el-table-column>
				<el-table-column label="总库存">
					<template slot-scope="scope">{{ scope.row.quantity }}件</template>
				</el-table-column>
				<el-table-column label="总销量">
					<template slot-scope="scope">{{ scope.row.buy_count }}件</template>
				</el-table-column>
				<el-table-column label="创建时间" width="120">
					<template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
				</el-table-column>
				<el-table-column  label="状态" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row | marketStatus }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="300" style="text-align: left;">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="success"
							@click="handleEditGoods(scope.row)">改价
						</el-button>
						<el-button
							size="mini"
							type="danger"
							v-if="scope.row.market_enable === 0"
							@click="handleDeleteGoods(scope.row)">删除
						</el-button>
						<el-button
							size="mini"
							type="danger"
							v-if="scope.row.market_enable === 1"
							@click="handleUnderGoods(scope.row)">下架
						</el-button>
						<el-button
							type="primary"
							size="mini"
							@click="handleStockGoods(scope.row)">库存
						</el-button>
						<!-- <el-button
							v-if="distributionSet"
							type="primary"
							size="mini"
							@click="handleRebate(scope.row)">返利
						</el-button> -->
					</template>
				</el-table-column>
			</template>
			<el-pagination
				slot="pagination"
				@size-change="handlePageSizeChange"
				@current-change="handlePageCurrentChange"
				:current-page="pageData.page_no"
				:page-size="pageData.page_size"
				:page-sizes="MixinPageSizes"
				:layout="MixinTableLayout"
				background
				:total="pageData.data_total">
			</el-pagination>
		</en-table-layout>
		<!--库存编辑-->
		<el-dialog title="库存编辑" :visible.sync="goodsStockshow" width="35%" class="pop-sku">
			<div align="center">
				<el-form :model="goodsStockData" ref="goodsStockData" v-if="goodsStocknums === 1" style="width: 50%;" label-width="100px" :rules="rules">
					<el-form-item label="库存" prop="quantity" >
						<el-input  v-model="goodsStockData.quantity" />
					</el-form-item>
					<el-form-item label="待发货数" >
						<el-input v-model="goodsStockData.deliver_goods_quantity"  disabled />
					</el-form-item>
				</el-form>
				<en-table-layout
					:tableData="goodsStockData"
					:loading="loading"
					border
					v-if="goodsStocknums > 1"
					:span-method="arraySpanMethod"
					:stripe="false">
					<template slot="table-columns">
						<el-table-column
							v-for="(item, index) in goodsStockTitle"
							v-if="item.prop !== 'sku_id'"
							:label="item.label"
							:key="index">
							<template slot-scope="scope">
								<el-input v-if="item.prop === 'quantity'" @blur="checkQuantity(scope.row.quantity)" v-model="scope.row.quantity"/>
								<span v-if="item.prop !== 'quantity'" >{{ scope.row[item.prop] }}</span>
							</template>
						</el-table-column>
					</template>
				</en-table-layout>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="goodsStockshow = false">取 消</el-button>
				<el-button type="primary" @click="reserveStockGoods">确 定</el-button>
			</div>
		</el-dialog>
		<!--下架原因-->
		<el-dialog title="下架原因" :visible.sync="isShowUnderReason" width="17%" >
			<div align="center">{{ under_reason }}</div>
		</el-dialog>
		<!--分销返利-->
		<el-dialog title="分销返利" :visible.sync="isShowDisRebate" width="24%">
			<el-form :model="disRebateData" label-width="100px" :rules="disRules" ref="disRebateData" status-icon>
				<el-form-item label="1级返利" prop="grade1Rebate">
					<el-input v-model="disRebateData.grade1Rebate">
						<template slot="prepend">¥</template>
					</el-input>
				</el-form-item>
				<el-form-item label="2级返利" prop="grade2Rebate">
					<el-input v-model="disRebateData.grade2Rebate">
						<template slot="prepend">¥</template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="reserveDisSet('disRebateData')">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import * as API_goods from '@/api/goods'
	import { CategoryPicker } from '@/components'
	import * as API_goodsCategory from '@/api/goodsCategory'
	import { RegExp } from '@/../ui-utils'
  import EnTableLayout from '../../../ui-components/TableLayout/src/main';
	export default {
		name: 'goodsList',
		components: {
      EnTableLayout,
			[CategoryPicker.name]: CategoryPicker
		},
		data() {
			const checkQuantity = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('库存不能为空'))
				}
				setTimeout(() => {
					if (!/^[0-9]\d*$/.test(value)) {
						callback(new Error('请输入整数'))
					} else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
						callback(new Error('请输入0 - 99999999之间的正整数'))
					} else {
						callback()
					}
				}, 500)
			}
			const checkMoney = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('返利金额不能为空'))
				}
				setTimeout(() => {
					if (!RegExp.money.test(value)) {
						callback(new Error('请输入正确的金额'))
					} else if (parseFloat(value) < 0 || parseFloat(value) > 99999999) {
						callback(new Error('请输入0~99999999之间的金额'))
					} else {
						callback()
					}
				}, 500)
			}

			return {
				market_enable: '',
				category_pathList: [],
				category_pathList1: [],
				category_path: '',
				category_path1: '',
				idsList: [],
				/** 列表loading状态 */
				loading: false,

				/** 列表参数 */
				params: {
					page_no: 1,
					page_size: 20,
					shopType:2,
					is_auth:'',
					keyword:'',
					goods_ptype:'',
					market_enable:'',
					low_price:null,
					high_price:null
				},

				/** 列表分页数据 */
				pageData: {data: []},

				/** 商品状态列表 */
				goodsStatusList: [
					{ value: 1, label: '上架' },
					{ value: 0, label: '下架' },
					{ value: 2, label: '审核中' },
					{ value: 4, label: '未通过' }
				],

				/** 商品类型 NORMAL 正常商品 POINT 积分商品 */
				goods_ptype: '',

				/** 当前商品分组*/
				categoryId: '',

				/** 当前商品id*/
				goodsId: '',

				/** 商品库存显示*/
				goodsStockshow: false,

				/** 是否显示下架原因 */
				isShowUnderReason: false,

				/** 下架原因 */
				under_reason: '',

				/** 库存商品数量*/
				goodsStocknums: 0,

				/** 商品库存列表数据*/
				goodsStockData: null,

				/** 商品库存列表表头数据 */
				goodsStockTitle: [],

				/** 要合并的列的位置数组 */
				concactArray: [],

				/** 校验规则 */
				rules: {
					quantity: [
						{ validator: checkQuantity, trigger: 'blur' }
					]
				},

				/** 店铺信息 */
				shopInfo: this.$store.getters.shopInfo,

				/** 分销设置是否开启 1开启 0关闭 */
				distributionSet: 0,

				/** 是否显示分销返利弹框 */
				isShowDisRebate: false,

				/** 分销返利数据 */
				disRebateData: {
					/** 商品id */
					goods_id: 0,

					/** 1级返利 */
					grade1Rebate: 0,

					/** 2级返利 */
					grade2Rebate: 0

				},

				/** 分销返利校验规则 */
				disRules: {
					grade1Rebate: [
						{ required: true, message: '1级返利金额不能为空', trigger: 'blur' },
						{ validator: checkMoney, trigger: 'blur' }
					],
					grade2Rebate: [
						{ required: true, message: '2级返利金额不能为空', trigger: 'blur' },
						{ validator: checkMoney, trigger: 'blur' }
					]
				}
			}
		},
		filters: {
			/** 销售状态格式化 */
			marketStatus(row) {
				switch (row.is_auth) {
					case 0 : return row.market_enable === 1 ? '待审核' : '已下架'
					case 1 : return row.market_enable === 1 ? '售卖中' : '已下架'
					case 2 : return row.market_enable === 1 ? '审核拒绝' : '已下架'
				}
			}
		},
		activated() {
			if (this.$route.query.market_enable === 0 || this.$route.query.market_enable === 1) {
				delete this.params.market_enable
			}
			this.params = {
				...this.params,
				...this.$route.query
			}
			this.GET_GoodsList()
			this.getDistributionSet()
		},
		mounted() {
			if (this.$route.query.market_enable === 0 || this.$route.query.market_enable === 1) {
				delete this.params.market_enable
			}
			this.params = {
				...this.params,
				...this.$route.query
			}
			this.GET_GoodsList()
			this.getDistributionSet()
			this.GET_NextLevelCategory()
		},
		methods: {
			upBatch(){
				if(!this.idsList.length){
					this.$message.error('请勾选商品')
				}else{
					this.$confirm('确认上架这些商品, 是否继续?', '提示', { type: 'warning' }).then(() => {
						API_goods.upBatchGoods(this.idsList.toString(), { goods_ids: this.idsList }).then(response => {
							this.dialogGoodsAuditVisible = false
							this.$message.success('上架成功！')
							this.GET_GoodsList()
						})
					})
				}
			},
			underBatch(){
				if(!this.idsList.length){
					this.$message.error('请勾选商品')
				}else{
					this.$confirm('确认下架这些商品, 是否继续?', '提示', { type: 'warning' }).then(() => {
						API_goods.underGoods(this.idsList.toString(), { goods_ids: this.idsList }).then(response => {
							this.dialogGoodsAuditVisible = false
							this.$message.success('下架成功！')
							this.GET_GoodsList()
						})
					})
				}
			},
			/** 库存边界限制 */
			checkQuantity(value) {
				if (!value && value !== 0) {
					this.$message.error('库存不能为空')
				} else if (!RegExp.integer.test(value) && parseInt(value) !== 0) {
					this.$message.error('请输入整数')
				} else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
					this.$message.error('请输入0 - 99999999之间的正整数')
				}
			},

			/** 分页大小发生改变 */
			handlePageSizeChange(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},

			/** 搜索事件触发 */
			searchEvent(data) {
				this.params = {
					...this.params,
					keyword: data
				}
				this.GET_GoodsList()
			},

			/** 切换上下架状态*/
			changeGoodsStatus(val) {
				console.log(val)
				if(val > 1){
					delete this.params.is_auth
					delete this.params.market_enable
					this.params = {
						...this.params,
						is_auth: val
					}
				}else{
					delete this.params.is_auth
					delete this.params.market_enable
					if (val !== '' && val !== -1) {
						this.params = {
							...this.params,
							market_enable: val
						}
					}
				}
				this.GET_GoodsList()
			},
			changeGoodsStatus1(val) {
				this.params.category_path = this.category_path
				this.category_pathList1 = []
				this.category_path1 = ''
				if(val){
					let _id = 0
					this.category_pathList.forEach((item, index) => {
						if(item.category_path == this.category_path){
							_id = item.category_id
						}
					})
					this.GET_NextLevelCategory({category_id: _id}, 1)
				}
				this.GET_GoodsList()
			},
			changeGoodsStatus2(val) {
				if(val){
					this.params.category_path = this.category_path1
				}else{
					this.params.category_path = this.category_path
				}
				this.GET_GoodsList()
			},

			/** 下架*/
			handleUnderGoods(row) {
				this.$confirm('确认下架此商品, 是否继续?', '提示', { type: 'warning' }).then(() => {
					API_goods.underGoods(row.goods_id, {}).then(() => {
						this.$message.success('下架成功')
						this.GET_GoodsList()
					})
				})
			},

			/** 切换商品类型 */
			changeGoodsType(val) {
				delete this.params.goods_ptype
				if (val !== '') {
					this.params = {
						...this.params,
						goods_ptype: val
					}
				}
				this.GET_GoodsList()
			},

			/** 切换分组*/
			changeGoodsCateGory(data) {
				delete this.params.shop_cat_path
				if (data && Array.isArray(data) && data.length !== 0) {
					this.params = {
						...this.params,
						shop_cat_path: '0|' + data.join('|') + '|'
					}
				}
				this.GET_GoodsList()
			},

			/** 显示下架原因 */
			showUnderReason(row) {
				this.isShowUnderReason = false
				this.isShowUnderReason = true
				this.under_reason = row.under_message
			},

			GET_GoodsList() {
				this.loading = true
				API_goods.getGoodsList(this.params).then(response => {
					this.loading = false
					this.pageData = response;
				})
			},

			/** 发布商品*/
			publishGoods() {
				this.$router.push({ name: 'goodPublish', params: { callback: this.GET_GoodsList }})
			},

			/** 跳转回收站*/
			gotoRecycle() {
				this.$router.push({ path: '/goods/recycle-station' })
			},

			/** 商品改价 */
			handleEditGoods(row) {
				this.changepricedata.goods_id = row.goods_id
				this.dialogVisibleg = true
				// this.$router.push({ name: 'goodsPublish', params: { goodsid: row.goods_id, isdraft: 1, callback: this.GET_GoodsList }})
			},

			/** 删除商品 */
			handleDeleteGoods(row) {
				this.$confirm('确认删除此商品, 是否继续?', '提示', { type: 'warning' }).then(() => {
					const _ids = [row.goods_id].toString()
					API_goods.deleteGoods(_ids).then(() => {
						this.GET_GoodsList()
						this.$message.success('删除商品成功！')
					})
				})
			},

			/** 合并数据相同的单元格 */
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if (columnIndex < this.goodsStockTitle.length - 3) {
					const _row = this.concactArray[rowIndex][columnIndex]
					const _col = _row > 0 ? 1 : 0
					return {
						rowspan: _row,
						colspan: _col
					}
				}
			},

			/** 计算要合并列的位置 */
			concactArrayCom(index, item) {
				let _isMerge = false
				/** 循环列 先循环第一列 若相同则合并 再循环第二列 依次循环 若不相同 则不合并并终止此列循环开始下一列循环 */
				let _currnetRow = []
				for (let i = 0, _len = this.goodsStockTitle.length - 3; i < _len; i++) {
					if (this.goodsStockTitle[i].prop === 'sku_id') {
						i++
						continue
					}
					if (index > 0 && item[this.goodsStockTitle[i].prop] !== this.goodsStockData[index - 1][this.goodsStockTitle[i].prop]) {
						_currnetRow[i] = 1
						_isMerge = true
					} else if (index > 0 && !_isMerge) {
						_currnetRow[i] = 0
						let _count = 1
						while (this.concactArray[index - _count][i] === 0) {
							_count++
						}
						this.concactArray[index - _count][i] += 1
					} else { // index === 0
						_currnetRow[i] = 1
					}
				}
				this.concactArray.push(_currnetRow)
			},

			/** 库存 */
			handleStockGoods(row) {
				this.goodsId = row.goods_id
				this.goodsStockshow = true
				setTimeout(() => { this.$refs['goodsStockData'].resetFields() })
				API_goods.getGoodsStockList(row.goods_id, {}).then((response) => {
					this.goodsStockTitle = this.goodsStockData = []
					this.goodsStocknums = response.length
					// 构造待发货字段
					if (response.length > 1) {
						this.$nextTick(() => {
							response.forEach((key) => {
								// 构造待发货字段
								this.$set(key, 'deliver_goods_quantity', parseInt(key.quantity) - parseInt(key.enable_quantity))
								// 构造表头
								let _skus = key.spec_list.map(elem => {
									return { label: elem.spec_name, prop: elem.spec_name }
								})
								this.goodsStockTitle = _skus.concat([
									{ label: '规格id', prop: 'sku_id' },
									{ label: '库存', prop: 'quantity' },
									{ label: '待发货数', prop: 'deliver_goods_quantity' }])
								// 构造表结构
								let _skuData = key.spec_list.map(elem => {
									let _map = new Map().set(elem.spec_name, elem.spec_value)
									let obj = Object.create(null)
									for (let [k, v] of _map) {
										obj[k] = v
									}
									return obj
								})
								const _key = {
									sku_id: key.sku_id,
									quantity: key.quantity,
									deliver_goods_quantity: key.deliver_goods_quantity
								}
								this.goodsStockData.push(Object.assign(_key, ..._skuData))
							})
							// 计算表格合并的位置
							this.concactArray = []
							this.goodsStockData.forEach((key, index) => {
								this.concactArrayCom(index, key)
							})
						})
					} else {
						response.forEach((key) => {
							// 构造待发货字段
							this.$set(key, 'deliver_goods_quantity', parseInt(key.quantity) - parseInt(key.enable_quantity))
						})
						this.goodsStockData = response[0]
					}
				})
			},

			/** 保存库存商品 */
			reserveStockGoods() {
				let _params = []
				if (Array.isArray(this.goodsStockData)) {
					_params = this.goodsStockData.map((elem) => {
						return {
							quantity_count: elem.quantity,
							sku_id: elem.sku_id
						}
					})
				} else {
					_params.push({
						quantity_count: this.goodsStockData.quantity,
						sku_id: this.goodsStockData.sku_id
					})
				}
				const _res = _params.some(key => {
					return !(parseInt(key.quantity_count) >= 0 && parseInt(key.quantity_count) < 99999999) || !/^[0-9]\d*$/.test(key.quantity_count)
				})
				if (_res) {
					this.$message.error('库存须为0 - 99999999之间的整数')
					return
				}
				API_goods.reserveStockGoods(this.goodsId, _params).then(() => {
					this.goodsStockshow = false
					this.$message.success('库存商品保存成功')
					this.GET_GoodsList()
				})
			},

			/** 获取分销设置 */
			getDistributionSet() {
				API_goods.getDistributionSet().then(response => {
					this.distributionSet = response.message
				})
			},

			/** 返利 获取返利信息*/
			handleRebate(row) {
				setTimeout(() => { this.$refs['disRebateData'].resetFields() })
				API_goods.getDistributionInfo(row.goods_id).then(response => {
					this.isShowDisRebate = true
					this.disRebateData = {
						/** 商品id */
						goods_id: response.goods_id || row.goods_id,

						/** 1级返利 */
						grade1Rebate: response.grade1_rebate,

						/** 2级返利 */
						grade2Rebate: response.grade2_rebate
					}
				})
			},

			/** 保存分销返利信息 */
			reserveDisSet(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						API_goods.setDistributionInfo(this.disRebateData).then(() => {
							this.isShowDisRebate = false
							this.$message.success('当前商品分销返利金额修改成功')
						})
					}
				})
			},
			handleSelectionChange(val){
				let ids = []
				val.forEach(item => {
					ids.push(item.goods_id)
				})
				this.idsList = ids
			},
			GET_NextLevelCategory(row, level) {
				this.loading = true
				const _id = row && row.category_id !== 0 ? row.category_id : 0
				API_goodsCategory.getGoodsCategoryLevelList(_id, { }).then((response) => {
					this.loading = false
					if (level == 1) {
						this.category_pathList1 = response
					} else if (!level) {
						this.category_pathList = response
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ div.toolbar {
		display: block;
		height: auto;
		overflow: hidden;
		padding: 20px 0;
	}

	/deep/ .el-table {
		width: 100%;
		overflow-x: scroll;
		& td:not(.is-left) {
			text-align: center;
		}
	}

	.inner-toolbar {
		display: block !important;
		width: 100%;
		align-items: center;
	}

	/deep/ .pop-sku {
		.toolbar {
			display: none;
		}
		.el-dialog__body {
			.el-table {
				border: 1px solid #e5e5e5;
			}
		}
		.el-table__body-wrapper {
			max-height: 400px;
			overflow-y: scroll;
		}
	}


	.toolbar-search {
		float: right;
		margin-right: 10px;
	}

	div.toolbar-btns {
		float: left;
		align-items: center;
		div {
			span {
				display: inline-block;
				font-size: 14px;
				color: #606266;
			}
		}
		.conditions {
			display: inline-block;
			margin-right: 10px;
			align-items: center;
			.choose-machine {
				width: 120px;
			}
		}
	}

	.goods-image {
		width: 50px;
		height: 50px;
	}

	/deep/ div.cell {
		overflow:hidden;

		text-overflow:ellipsis;

		display:-webkit-box;

		-webkit-box-orient:vertical;

		-webkit-line-clamp:2;
	}
	/*下架原因*/
	.under-reason {
		color: red;
		cursor: pointer;
	}

</style>
